<template>
  <div class="page-container collaborators-page">
    <portal to="page-name">Colaboradores</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Colaboradores</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope" v-focus>
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left mr-2"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Novo colaborador
            </button>
            <button class="btn btn-gray btn-icon btn-icon-left"
                    @click="openModalPrint">
              <fa-icon :icon="['fal', 'print']"/>Imprimir
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <table class="table table-striped table-hover" v-else>
          <thead>
          <tr>
            <th class="hide-sm" width="40px">#</th>
            <th>Nome</th>
            <th class="hide-sm">CPF</th>
            <th class="hide-sm hide-md">Telefone</th>
            <th class="hide-sm hide-md">Ativo</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + 1 }}</td>
            <td><router-link :to="editLink(item)">{{ item.name }}</router-link></td>
            <td class="hide-sm">
              <span v-if="item.identity">{{ item.identity.value | cpf }}</span>
              <span v-else>--</span>
            </td>
            <td class="hide-sm hide-md">
              {{ item.cellphone ? item.cellphone : item.phone | phone }}
            </td>
            <td class="hide-sm hide-md">
              {{ item.collaborator.active ? 'Sim' : 'Não' }}
            </td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                      @click="edit(item)" data-tooltip="Editar">
                <fa-icon :icon="['fal', 'pencil']"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
    <dx-modal title="Relação de colaboradores"
              :value="printModal.show" size="sm"
              id="modal-collaborator-print" @input="printModal.show = false">
      <div class="columns">
        <div class="column col-12 form-group">
          <label for="filter-status" class="form-label">Status</label>
          <select id="filter-status" class="form-select"
                  v-model="printModal.active">
            <option value="all">Todos os colaboradores</option>
            <option value="disabled">Somente os inativos</option>
            <option value="enabled">Somente os ativos</option>
          </select>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                :class="{loading: printModal.printing}"
                :disabled="printModal.printing"
                @click="print">
          <fa-icon :icon="['fal', 'print']"/>Imprimir
        </button>
        <button class="btn" @click="printModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: '/collaborators',
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      filter: {
        search: '',
      },
      data: {
        items: [],
        hasMore: false,
        limit: 20,
        offset: 0,
      },
      printModal: {
        show: false,
        printing: false,
        active: 'enabled',
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
    openModalPrint() {
      this.printModal.active = 'enabled';
      this.printModal.show = true;
    },
    print() {
      this.printModal.printing = true;
      const params = {};
      if (this.printModal.active) {
        if (this.printModal.active === 'enabled') {
          params.active = true;
        }
        if (this.printModal.active === 'disabled') {
          params.active = false;
        }
      }
      return this.$file
        .print('/collaborators/print', params)
        .catch(() => {})
        .then(() => {
          this.printModal.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .collaborators-page {
    .btn-fixed-width {
      font-size: $font-size-lg;
      height: 2.2rem;
      margin: $layout-spacing-lg 0;
      width: 11rem;
    }
  }
</style>
